import { Recipe } from "@recipes/lib";
import { Link } from "react-router-dom";
import { Authenticated } from "./Authenticated";
import { Guest } from "./Guest";
import { Icon } from "./Icon";

export const NavMenu = () => {
  return (
    <div className="z-[1] text-left inset-0 mt-16 absolute bg-base-200 px-6 py-3">
      <div>
        <RecentlyViewed />
      </div>
      <Authenticated>
        <Link to="/author" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.MyRecipes className="w-10" />
          <div className="flex-1 text-left">MY RECIPES</div>
          <Icon.ChevronRight className="w-10" />
        </Link>
      </Authenticated>
      <Authenticated>
        <Link to="/recipe/add" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.AddRecipe className="w-10" />
          <div className="flex-1 text-left">ADD RECIPES</div>
          <Icon.ChevronRight className="w-10" />
        </Link>
      </Authenticated>
      <Authenticated>
        <Link to="/followers" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.Info className="w-10" />
          <div className="flex-1 text-left">FRIENDS</div>
          <Icon.ChevronRight className="w-10" />
        </Link>
      </Authenticated>
      <Authenticated>
        <Link to="/profile" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.User className="w-10" />
          <div className="flex-1 text-left">PROFILE</div>
          <Icon.ChevronRight className="w-10" />
        </Link>
      </Authenticated>
      <Authenticated>
        <a href="/api/auth/logout" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.ChevronRight className="w-10" />
          <div className="flex-1 text-left">
            <div>LOGOUT</div>
          </div>
        </a>
      </Authenticated>
      <Guest>
        <a href="/api/auth/google/login" className="flex items-center gap-4 btn no-animation mb-4">
          <Icon.User className="w-10" />
          <div className="flex-1 text-left">LOGIN</div>
        </a>
      </Guest>
    </div>
  );
};

const RecentlyViewed = () => {
  const MAX_VIEWABLE = 3;
  const recentRecipes: Recipe[] = [];

  if (recentRecipes.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div className="italic">Recently viewed...</div>
      <div>
        {recentRecipes.slice(0, MAX_VIEWABLE).map((recipe, idx) => (
          <div key={idx} className="mt-4">
            <RecipeListItem recipe={recipe} />
            <div className="divider" />
          </div>
        ))}
      </div>
    </div>
  );
};

const RecipeListItem = (props: { recipe: Recipe }) => {
  return (
    <Link to={`/recipe/${props.recipe.id}`} className="flex items-center gap-4">
      <img className="w-16" src={props.recipe.headerImage} width={24} height={24} alt="Recipe Image" />
      <div>{props.recipe.title}</div>
    </Link>
  );
};
