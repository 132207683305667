import { useSession } from "../context/SessionContext";
import { Icon } from "./Icon";

export const UserAvatar = (props: { onClick: () => void }) => {
  const { user } = useSession();
  if (!user.authenticated) {
    return (
      <div className="avatar cursor-pointer" onClick={props.onClick}>
        <div className="w-8 rounded-full">
          <Icon.User />
        </div>
      </div>
    );
  }
  if (user.picture) {
    return (
      <div className="avatar cursor-pointer" onClick={props.onClick}>
        <div className="w-8 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
          <img
            referrerPolicy="no-referrer"
            style={{ borderRadius: "50%" }}
            src={user.picture}
            height={30}
            width={30}
            alt="User Picture"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="avatar cursor-pointer" onClick={props.onClick}>
      <div className="w-8 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
        <span className="flex items-center justify-center text-white bg-primary w-8 h-8 rounded">
          {user.handle ?? ":)"}
        </span>
      </div>
    </div>
  );
};
