import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  HomeIcon,
  InformationCircleIcon,
  PlusIcon,
  StarIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline";

// https://heroicons.com/
// redefine the icons we actually use here...
export const Icon = {
  AddRecipe: DocumentPlusIcon,
  ChevronDown: ChevronDownIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChevronUp: ChevronUpIcon,
  Close: XMarkIcon,
  Error: ExclamationCircleIcon,
  Home: HomeIcon,
  Info: InformationCircleIcon,
  MyRecipes: DocumentTextIcon,
  Pencil: PencilIcon,
  Plus: PlusIcon,
  StarOutline: StarOutlineIcon,
  StarSolid: StarIcon,
  Trash: TrashIcon,
  User: UserIcon,
  Warning: ExclamationTriangleIcon,
};
